@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-[#27343A]
  }
}

@font-face {
  font-family: "STEELFISH";
  src: url("../public/fonts/steelfish_rg.otf")
}